/* eslint-disable no-console */
'use client';

import clsx from 'clsx';
import Image from 'next/image';
import React, {
  useCallback,
  useEffect,
  useLayoutEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import useSWR from 'swr';

import { getGames } from '@/lib/apis';
import { compareText } from '@/lib/helper';
import {
  cateMapping,
  findCateByName,
  getParameterByName,
  hotMapping,
  updateQueryStringParameters,
} from '@/lib/utils';

import PlaceHolderGameLoading from '@/components/modules/Loading/IndicatorLoading/PlaceHolderGameLoading';
import TopMenu, { LobbyProvider } from '@/components/modules/Menu/TopMenu';
import TopSlider from '@/components/modules/Slider/TopSlider';

import FilterProviders from '@/app/[lng]/(home)/components/Mobile/components/FilterProviders';
import HotGames from '@/app/[lng]/(home)/components/Mobile/components/HotGames';
import {
  hearted,
  lobby,
  recentPlay,
} from '@/app/[lng]/(home)/components/Mobile/components/Renderer/RenderCateItemGames';
import { ProviderNonLobby } from '@/constant/data';
import { gameMenuMobile } from '@/constant/gameMenu';

const LIMIT_GAME = 51;

const MobileHomeGames = ({
  topMatch,
  slotJackpot,
  firstDataSSR = {},
  searchParams,
  hotGamesData,
  providersWithSlugs,
}: {
  params?: {
    lng?: string;
    id?: string;
    partnerProvider?: string;
  };
  topMatch?: any;
  slotJackpot?: any;
  firstDataSSR?: any;
  searchParams?: any;
  hotGamesData?: any;
  providersWithSlugs?: any;
}) => {
  const slug = searchParams?.slug || 'hot';
  const partnerProvider = getParameterByName('partner-provider') || '';
  const categoryParam = partnerProvider ? 'all' : searchParams?.category;
  const cateDetailParam = findCateByName(
    getParameterByName('category') || ''
  )?.value;
  const initWithSSR = useRef(0);
  // TODO: filter from param XSS
  const [gamePage, setGamePage] = useState(() => slug);
  const items: any = useMemo(
    () => gameMenuMobile.find((_) => _.slug === gamePage),
    [gamePage]
  );
  const [partnerSelected, setPartnerSelected] = useState(partnerProvider);

  const [cateDetail, setCateDetail] = useState(
    categoryParam || cateDetailParam || 'all'
  );
  console.log('__cateDetail__', cateDetail);

  const [provider, setProvider] = useState('');
  const [pageNo, setPageNo] = useState(1);
  const [isNextLoading, setIsNextLoading] = useState(false);
  const pageRef = useRef(1);

  useEffect(() => {
    const func = {
      setGamePage: setGamePage,
      setCateDetail: setCateDetail,
      setProvider: setPartnerSelected,
    };
    window.homePage = func;
  }, [slug, gamePage]);

  useLayoutEffect(() => {
    setGamePage(slug);
  }, [slug]);

  const isCallSWR = !(firstDataSSR?.isInitGames && initWithSSR.current === 0);

  const {
    data: games,
    isLoading,
    isValidating,
    mutate,
  } = useSWR(
    isCallSWR &&
      !(
        cateDetail === recentPlay ||
        cateDetail === hearted ||
        cateDetail === lobby
      )
      ? '/get-games' + items?.display + partnerSelected + cateDetail
      : null,
    () => {
      return getGames({
        limit: LIMIT_GAME,
        partner: partnerSelected,
        device: 'mobile',
        display:
          cateMapping(cateDetail) === 'hot'
            ? `17,${hotMapping(items?.display)}`
            : hotMapping(items?.display),
        display_all: cateMapping(cateDetail) === 'hot',
        category_id: cateMapping(cateDetail),
        page: pageNo,
      });
    },
    {
      revalidateOnFocus: false,
      revalidateOnMount: isCallSWR,
    }
  );

  const fetchMoreData = useCallback(async () => {
    try {
      setIsNextLoading(true);
      pageRef.current = pageRef.current + 1;
      if (!isCallSWR) {
        setPageNo((prev) => prev + 1);
        return;
      }
      const newDataResponse = await getGames({
        limit: LIMIT_GAME,
        partner: partnerSelected,
        device: 'mobile',
        display:
          cateMapping(cateDetail) === 'hot'
            ? `17,${hotMapping(items?.display)}`
            : hotMapping(items?.display),
        category_id: cateMapping(cateDetail),
        page: pageRef.current, // Incremented page number
        display_all: cateMapping(cateDetail) === 'hot',
      });

      mutate((prevData) => {
        const items = [
          ...(prevData?.data?.items || []),
          ...(newDataResponse?.data?.items || []),
        ];
        const data = {
          ...(newDataResponse?.data || {}),
          items,
        };
        const newData = {
          ...(newDataResponse || {}),
          data,
        };

        return newData;
      }, false);
    } catch (error) {
      console.error('fetchMoreData failure by ', error);
    } finally {
      setIsNextLoading(false);
    }
  }, [cateDetail, items?.display, mutate, partnerSelected, pageRef, isCallSWR]);

  const loading = useMemo(
    () =>
      (Boolean(isLoading) || Boolean(isValidating)) &&
      !games?.data?.items &&
      isCallSWR,
    [games?.data?.items, isLoading, isValidating, isCallSWR]
  );

  let CurrentGamePage = items?.component?.({
    topMatch,
    items,
    slotJackpot,
    cateDetail,
    games: !isCallSWR
      ? firstDataSSR?.initGames
      : { ...firstDataSSR?.initGames, ...games },
    loading,
    firstDataSSR,
    partnerProvider,
    fetchMoreData,
    isNextLoading,
    setProvider,
    partner: partnerSelected,
    gamePage,
  } as any);

  const pageProvider = useMemo(
    () =>
      ProviderNonLobby.find((_) => {
        return (
          provider === _.slug ||
          Boolean(provider && _.slug.includes(provider)) ||
          Boolean(
            provider &&
              Array.isArray(_.slug) &&
              _.slug?.join(',').includes(provider)
          ) ||
          Boolean(provider && compareText(_.label, provider))
        );
      }),
    [provider]
  );

  const handleClickMenu = (id: string) => {
    setGamePage(id);
    if (provider !== '') setProvider('');
    if (cateDetail !== 'hot') setCateDetail('all');
    if (partnerSelected !== '') setPartnerSelected('');
    if (pageNo !== 1) setPageNo(1);
    initWithSSR.current = 1;
  };

  const handSelectValue = (value: any) => {
    setPartnerSelected(value);
    if (value === '') {
      setCateDetail('hot');
    } else {
      setCateDetail('all');
    }
    const searchParams = {
      'partner-provider': value,
    };
    window.history.replaceState(
      null,
      '',
      updateQueryStringParameters(window.location.href, searchParams, false)
    );
    initWithSSR.current += 1;
  };

  const handleSelectCateDetail = (value: any) => {
    setCateDetail(value);
    const searchParams = {
      category: value,
    };
    window.history.replaceState(
      null,
      '',
      updateQueryStringParameters(window.location.href, searchParams, false)
    );
    initWithSSR.current += 1;
  };

  if (pageProvider) {
    CurrentGamePage = LobbyProvider?.component({
      data: pageProvider,
      params: [items?.display, provider],
      slotJackpot,
      handleClickMenu,
    } as any);
  }

  return (
    <section className='bg-[#eaeaea]'>
      <div className='pointer-events-none fixed inset-0 bottom-0 left-0 right-0 top-0 z-[-1] h-full w-full bg-[#eaeaea]' />
      <TopMenu active={gamePage} handleClick={handleClickMenu} />
      <div className={clsx(gamePage !== 'hot' && 'hidden')}>
        <TopSlider />
        <HotGames
          providersWithSlugs={providersWithSlugs}
          setProvider={(value: any) => {
            const { slug = '', partner_provider = '' } = value || {};
            window.history.replaceState(
              null,
              '',
              updateQueryStringParameters(window.location.href, {
                slug,
                'partner-provider': provider,
                category: cateDetail,
              })
            );
            if (slug) setGamePage(slug);
            if (partner_provider) setPartnerSelected(partner_provider);
            if (cateDetail) setCateDetail(cateDetail);

            window.scrollTo(0, 0);
          }}
          hotGamesData={hotGamesData}
          topMatch={topMatch}
          viewMore={({
            slug,
            cateDetail,
            provider,
          }: {
            slug: string;
            cateDetail: any;
            provider: string;
          }) => {
            window.history.replaceState(
              null,
              '',
              updateQueryStringParameters(window.location.href, {
                slug,
                'partner-provider': provider,
                category: cateDetail,
              })
            );

            if (slug) setGamePage(slug);
            setPartnerSelected(provider);
            setCateDetail(cateDetail);

            window.scrollTo(0, 0);
          }}
        />
      </div>
      {/* )} */}
      {/* {gamePage !== 'hot' && ( */}
      <div className={clsx(gamePage === 'hot' && 'hidden')}>
        {items?.mobileBanner && (
          <Image
            width={393}
            height={100}
            priority
            className='vw:h-[100px] vw:w-[393px] loading-bg'
            src={items?.mobileBanner}
            alt={items?.mobileBanner}
          />
        )}
        {items?.display === 'hot' && <TopSlider />}
        {items?.display && items?.display !== 'hot' && (
          <div className='mt-4 overflow-hidden'>
            <FilterProviders
              items={items}
              setSelectValue={handSelectValue}
              setCateDetail={handleSelectCateDetail}
              selectValue={partnerSelected}
              cateDetail={cateDetail}
              params={[items?.display]}
            />
          </div>
        )}
        {!loading && (
          <div className='bg-neutral-1 flex h-full w-full'>
            <div className='h-full w-full'>{CurrentGamePage}</div>
          </div>
        )}
        {loading && (
          <div className='vw:pb-[90px] pt:2 xs:grid-cols-3 grid grid-cols-2 gap-2 px-3'>
            <PlaceHolderGameLoading className='!aspect-[4/3]' amount={27} />
          </div>
        )}
      </div>
      {/* )} */}
    </section>
  );
};

export default MobileHomeGames;
